import React, { useState, useCallback, useEffect } from "react";
import { Card, makeStyles, Typography, Grid, CardContent, Button, CardActions, TextField, CardMedia, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box } from "@material-ui/core"
import fx from "fireworks"
import finalImage from "./img/question.png"
import ringImage from "./img/ring.png"

const testmode = false

interface BaseProps {
    pageNumber: number
    requiredPopoverCode: string
    popoverImage: string
    pageImage:string
    title: string
    riddleText: string
    riddleClue: string
    nextPageCode: string
    nextPagePath: string
}

interface PopoverProps {
    pageNumber: number
    requiredPopoverCode: string
    popoverImage: string
    onCodeCorrect: () => void
}

const useStyles = makeStyles({
    root: {
        backgroundColor: "#ebfaeb",
        minHeight: "100vh",
    },
    card: {
        backgroundColor: "#e3e3e1",
        width: "300px",
    },
    cardContent: {
        backgroundColor: "white",
        margin: "14px",
    },
    grid: {
        width: "100vw",
        minHeight: "100vh",
    },
    media: {
        minHeight: "300px",
    },
    button: {
        width: "100%"
    },
    title: {
        width: "100%"
    },
    pageImage: {
        width: "300px",
        maxHeight: "200px"
    },
    pageText: {
        textAlign: "center"
    },
    codeHint: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    goBackError: { 
        color: "red"
    }
});

function matchAnyCase(one: string, two: string): boolean{
    return one.toLowerCase() === two.toLowerCase()
}

const PagePopover = (props: PopoverProps) => {
    const classes = useStyles()
    const [codeInput, setCodeInput] = useState<string>("");
    const [inputError, setInputError] = useState(false);

    const onSubmit = useCallback(() => {
        if (matchAnyCase(props.requiredPopoverCode, codeInput)) {
            props.onCodeCorrect()
            setInputError(false)
        } else {
            setInputError(true)
        }
    }, [codeInput, setInputError, props])

    return (
        <Grid className={classes.grid} container direction={"row"} justify={"center"} alignContent={"center"}>
            <Grid item>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.media}
                        image={props.popoverImage}
                    />
                    <div className={classes.cardContent}>
                        <CardContent>
                            <Grid container justify={"center"}>
                                <Grid item >
                                    <Typography gutterBottom variant="h5" component="h2">
                                       Puzzle {props.pageNumber}
                                    </Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <TextField className={classes.button}
                                        id="outlined-input"
                                        label={`Clue Code ${props.pageNumber}`}
                                        helperText={inputError ? "Incorrect code" : "Enter secret code"}
                                        variant="outlined"
                                        error={inputError}
                                        value={codeInput}
                                        onChange={(event) => {
                                            setCodeInput(event.target.value)
                                            setInputError(false)
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </CardContent>
                        <CardActions>

                            <Button className={classes.button} variant={"outlined"} onClick={onSubmit}>
                                Submit
                        </Button>
                        </CardActions>
                    </div>
                </Card>
            </Grid>
        </Grid>


    )

}

const PageContent = (props: BaseProps) => {
    const classes = useStyles()
    const [revealHint, setRevealHint] = useState(false);
    const onRevealHint = useCallback(() => {
        setRevealHint(true)
    }, [setRevealHint])

    const [revealCode, setRevealCode] = useState(false);
    const onRevealCode = useCallback(() => {
        setRevealCode(true)
    }, [setRevealCode])

    return <Grid className={classes.grid} container direction={"row"} justify={"center"} alignContent={"center"}>
        <Grid item>
            <Card className={classes.card}>
                <CardMedia
                    className={classes.media}
                    image={props.pageImage}
                />
                <div className={classes.cardContent}>
                    <CardContent>
                        <Grid container justify={"center"}>
                            <Grid item className={classes.pageText}>
                                <Typography gutterBottom variant="h6" component="h2">
                                    {props.title}
                                </Typography>
                                <Typography gutterBottom variant={"caption"} component="p">
                                    {props.riddleText}
                                </Typography>
                            </Grid>

                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container spacing={2}>
                            <Grid item xs={12} spacing={8}>
                                {revealHint
                                    ? (<TextField
                                        style={{
                                          width: "100%"
                                      }}
                                        id="page1hint"
                                        label="hint"
                                        variant="outlined"
                                        disabled={true}
                                        value={props.riddleClue}
                                      />)
                                    : <Button className={classes.button} fullWidth variant={"outlined"} onClick={onRevealHint}>
                                        Reveal Hint
                                      </Button>
                                }
                            </Grid> 
                            {revealHint && <Grid item xs={12} spacing={8}>
                            <Typography gutterBottom variant={"caption"} component="p" style={{textAlign: "center"}}>
                                Find the QR Code to get to puzzle {props.pageNumber + 1} and use the code below
                                </Typography>
                            </Grid> }                         
                            <Grid item xs={12} spacing={8}>
                                {revealCode ? <Button disableRipple={true} className={classes.codeHint} fullWidth variant={"outlined"}>
                                    {props.nextPageCode}
                                </Button>
                                    : <Button className={classes.button} fullWidth variant={"outlined"} onClick={onRevealCode}>
                                        Reveal Clue Code {props.pageNumber + 1}
                                    </Button>}
                            </Grid>
                            {testmode && <a href={`http://${window.location.host}${props.nextPagePath}`}>NEXT</a>}
                        </Grid>


                    </CardActions>
                </div>
            </Card>
        </Grid>
    </Grid>
}

function tryAgainText(tryAgain: number): string {
    if(tryAgain === 0) {
        return "Not Sure"
    } else if(tryAgain < 3) {
        return "Try Again"
    } else if(tryAgain < 5) {
        return "Wow"
    } else if(tryAgain < 7) {
        return "Ouch" 
    }
    return "Just Press Yes Already!"
}

const FinalPageContent = () => {
    const classes = useStyles()
    const [buttonState, setButtonState] = useState<"reveal" | "areYouSure" | "show">
        ("reveal")

    const [goBackPressed, setGoBackPressed] = useState(false)
    const [finalContinuePressed, setFinalContinuePressed] = useState(false)
    const [tryAgain, setTryAgain] = useState(0)
    const [YES, setYES] = useState(false)

    useEffect(()=> {
        if(YES) {
            setInterval(() =>
                fx({
                    x: (Math.random()*window.innerWidth),
                    y: (Math.random()*window.innerWidth),
                    colors: ['#cc3333', '#4CAF50', '#81C784']
                }),
            250)
        }
    }, [YES])
    return <Grid className={classes.grid} container direction={"row"} justify={"center"} alignContent={"center"}>
        <Grid item>
            <Card className={classes.card}>
                <CardMedia
                    className={classes.media}
                    image={finalContinuePressed ? ringImage : finalImage}
                />
                {YES && <div className={classes.cardContent}>
                    <CardContent>
                    <Grid container justify={"center"}>
                            <Grid item className={classes.pageText}>
                                <Typography className={classes.title} gutterBottom variant="h6" component="h2">
                                    SHE SAID YES!
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    </div>}
                {!YES && <div className={classes.cardContent}>
                    <CardContent>
                        <Grid container justify={"center"}>
                            <Grid item className={classes.pageText}>
                                <Typography className={classes.title} gutterBottom variant="h6" component="h2">
                                    {!finalContinuePressed ? "The Big One" : "Will You Marry Me??"}
                                </Typography>
                                {!finalContinuePressed && <Typography gutterBottom variant={"caption"} component="p">
                                    Not so much a riddle as it is a question, and boy is it a doozie...
                            </Typography>}
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container spacing={2}>
                            <Grid item xs={12} spacing={8}>
                                {buttonState !== "show" && !finalContinuePressed && <Button
                                    fullWidth
                                    variant={"outlined"}
                                    onClick={() => {
                                        if (buttonState === "reveal") {
                                            setButtonState("areYouSure")
                                        } else if (buttonState === "areYouSure") {
                                            setButtonState("show")
                                        }
                                    }}
                                >
                                    {buttonState === "reveal" ? "Reveal Question" : buttonState === "areYouSure" ? "Are You Sure?" : ""}

                                </Button>}
                                <Dialog
                                    open={buttonState === "show" && !finalContinuePressed}
                                >
                                    <DialogTitle>Warning! No going back!</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Once the question is asked, you cannot un-ask the question, be very sure before continuing.
    </DialogContentText>
                                        {goBackPressed && <DialogContentText className={classes.goBackError}>
                                            Error, "Go Back" is not a valid response
    </DialogContentText>}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setGoBackPressed(true)}>Go Back</Button>
                                        <Button onClick={() => {
                                            setFinalContinuePressed(true)
                                        }}>Continue</Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                            <Grid item xs={12} spacing={8}>
                                {finalContinuePressed &&
                                    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                                        <Button onClick={()=> {setYES(true)}}variant={"outlined"} fullWidth size={"large"}>YES!</Button>
                                        <Box display={"flex"} justifyContent={"center"} marginTop={1}>
                                            <Button onClick={() => setTryAgain((old) => old+1)} size={"small"} variant={"outlined"}>{tryAgainText(tryAgain)}</Button>
                                        </Box>
                                    </Box>
                                }
                            </Grid>
                        </Grid>


                    </CardActions>
                </div>}
            </Card>
        </Grid>
    </Grid>
}



export const Page = (props: BaseProps) => {
    const classes = useStyles()
    const [showPopover, setShowPopover] = useState(true);
    const onCodeCorrect = useCallback(() => {
        setShowPopover(false)
    }, [setShowPopover])

    return (
        <div className={classes.root}>
            {showPopover
                ? <PagePopover {...props} onCodeCorrect={onCodeCorrect} />
                : <PageContent {...props} />}
        </div>
    )
}

export const FinalPage = (props: PopoverProps) => {
    const classes = useStyles()
    const [showPopover, setShowPopover] = useState(true);
    const onCodeCorrect = useCallback(() => {
        setShowPopover(false)
    }, [setShowPopover])

    return (
        <div className={classes.root}>
            {showPopover
                ? <PagePopover {...props} onCodeCorrect={onCodeCorrect} />
                : <FinalPageContent />}
        </div>
    )
}