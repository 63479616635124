import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { FinalPage, Page } from './Page';
import green1 from "./img/green1.jpg"
import green2 from "./img/green2.jpg"
import green3 from "./img/green3.jpg"
import green4 from "./img/green4.jpg"
import green5 from "./img/green5.jpg"
import green6 from "./img/green6.jpg"

import firstDateImage from "./img/discGolf.png"
import carDateImage from "./img/carDate.png"
import soundRoomImage from "./img/headphones.png"
import becameCoupleImage from "./img/fountain.jpg"
import backYardImage from "./img/backyard.jpg"

const firstDate = {
  clue : "START",
  path: "/",
  popover: green2,
  page: firstDateImage
}
const becameCouple = {
  clue: "KIRK",
  path: "/dd80098b",
  popover: green1,
  page: becameCoupleImage
}


const carDate = {
  clue: "PICARD",
  path: "/21733dea",
  popover: green3,
  page: carDateImage
}

const soundRoom = {
  clue: "SISKO",
  path: "/be3d079b",
  popover: green4,
  page: soundRoomImage
}

const backyard = {
  clue: "JANEWAY",
  path: "/de221e76",
  popover: green5,
  page: backYardImage
}

const final = {
  clue: "ARCHER",
  path: "/8d8d785a",
  popover: green6,
}

function App() {
  return (
    <Router>
      <Switch>
      <Route path={becameCouple.path}>
          <div>
            <Page
              pageNumber={2}
              requiredPopoverCode={becameCouple.clue}
              popoverImage={becameCouple.popover} 
              pageImage={becameCouple.page}
              title="The Real Beginning" 
              riddleText={`Everything starts somewhere, this spot is where we became a couple`}
              riddleClue={"Don't let the koi bite you!"}
              nextPageCode={carDate.clue}
              nextPagePath={carDate.path}
               />
          </div>
        </Route>
        <Route path={carDate.path}>
          <div>
            <Page
              pageNumber={3}
              requiredPopoverCode={carDate.clue}
              pageImage={carDate.page}
              popoverImage={carDate.popover}
              title="The Car Picnic"
              riddleText={`Lockdown makes everything a little more challenging, but there is always a way to have a great picnic`}
              riddleClue={"First spot, best spot"}
              nextPageCode={soundRoom.clue}
              nextPagePath={soundRoom.path}
               />
          </div>
        </Route>
        <Route path={soundRoom.path}>
          <div>
            <Page
              pageNumber={4}
              requiredPopoverCode={soundRoom.clue}
              popoverImage={soundRoom.popover}
              pageImage={soundRoom.page}
              title="The Upper Room"
              riddleText={<><i>Somehow</i> we always end up here together... funny how that works.</> as any}
              riddleClue={"gonna have to climb some stairs to get there"}
              nextPageCode={backyard.clue}
              nextPagePath={backyard.path}
               />
          </div>
        </Route>
        <Route path={backyard.path}>
          <div>
            <Page
              pageNumber={5}
              requiredPopoverCode={backyard.clue}
              pageImage={backyard.page}
              popoverImage={backyard.popover}
              title="The Yard"
              riddleText={"Pick some cherries, have a campfire, smoke some meat, this is a great place to do all those things"}
              riddleClue={"This location hits close to home"}
              nextPageCode={final.clue}
              nextPagePath={final.path}
               />
          </div>
        </Route>
        <Route path={final.path}>
          <div>
            <FinalPage
              pageNumber={6}
              requiredPopoverCode={final.clue}
              popoverImage={final.popover}
              onCodeCorrect={() => {}} //handled in the page
            />
          </div>
        </Route>
        <Route path={firstDate.path}>
          <div>
            <Page
              pageNumber={1}
              requiredPopoverCode={firstDate.clue}
              popoverImage={firstDate.popover}
              pageImage={firstDate.page}
              title="The First Date"
              riddleText="To find your first clue, first look high, then look low. From where I am you can see for miles and throw just as far."
              riddleClue="52.117333, -106.721611"
              nextPageCode={becameCouple.clue}
              nextPagePath={becameCouple.path}
               />
          </div>
        </Route>
        
      </Switch>
    </Router>
  );
}

export default App;
